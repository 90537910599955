import PropTypes from 'prop-types';
import Head from 'next/head';
export default function Layout({ children }) {
  return (
    <div>
      <Head>
        <link rel='icon' href='/favicon.ico' />
        <meta name='og:title' content='APPDA' />
        <title>APPDA</title>
      </Head>
      {children}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
