import React, { useContext, useState, useMemo, useEffect } from 'react';

import denormalize from '@weareredlight/denormalize_json_api';

import api from '../api';
import { get } from '../utils/localStorage';

const UserContext = React.createContext();

const { Provider, Consumer } = UserContext;

export const UserContextConsumer = Consumer;

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('Clients of useUserContext must be wrapped inside a <UserContextProvider />');
  }

  return context;
};

export const UserContextProvider = (props) => {
  const [isSessionLoaded, setIsSessionLoaded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [isCaretaker, setIsCaretaker] = useState(false);
  const [kids, setKids] = useState([]);

  useEffect(() => {
    const accessToken = get('ACCESS_TOKEN');
    const email = get('EMAIL');
    if (accessToken && email) {
      setIsLoggedIn(true);
    } else {
      setIsSessionLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn && !user) {
      api
        .getUserInfo()
        .then((resp) => {
          const userData = denormalize(resp.data.data).data;
          setUser(userData);
          setKids(userData.profilable.children);
          setIsCaretaker(userData.profilable_type === 'Caretaker');
        })
        .finally(() => {
          setIsSessionLoaded(true);
        });
    }
  }, [isLoggedIn]);

  const refreshUser = (callback) => {
    if (isLoggedIn) {
      api.getUserInfo().then((resp) => {
        const userData = denormalize(resp.data.data).data;
        setUser(userData);
        setKids(userData.profilable.children);
        setIsCaretaker(userData.profilable_type === 'Caretaker');
        callback && callback();
      });
    }
  };

  const context = useMemo(
    () => ({
      isSessionLoaded,
      isLoggedIn,
      setIsLoggedIn,
      setIsSessionLoaded,
      user,
      isCaretaker,
      kids,
      refreshUser,
    }),
    [
      isSessionLoaded,
      isLoggedIn,
      setIsLoggedIn,
      setIsSessionLoaded,
      user,
      isCaretaker,
      kids,
      refreshUser,
    ],
  );

  return <Provider value={context} {...props} />;
};
