import PropTypes from 'prop-types';

import { UserContextProvider, UserContextConsumer } from '../hooks/useUser';

import Layout from '../components/layout';

import '../styles/global.scss';

import Loading from '../components/loading';

export default function App({ Component, pageProps }) {
  return (
    <Layout>
      <UserContextProvider>
        <UserContextConsumer>
          {({ isSessionLoaded }) =>
            isSessionLoaded ? <Component {...pageProps} /> : <Loading loadingKind='loadingPage' />
          }
        </UserContextConsumer>
      </UserContextProvider>
    </Layout>
  );
}

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object,
};
